import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  StyleSheet,
  Platform,
  Animated,
  TouchableWithoutFeedback,
} from "react-native";
import {
  checkIfFollows,
  getBuyersData,
  getUserProfile,
  checkHiddenEvents,
} from "../../../services/cruds/users/getUser";
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";
import emptyAvatarImage from "../../../../assets/imgs/avatar_vazio.png"; // Imagem placeholder
import { SafeAreaView } from "react-native-safe-area-context";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const BuyersList = ({ evento, uid, navigation }) => {
  const [publicBuyers, setPublicBuyers] = useState([]);
  const [privateBuyersCount, setPrivateBuyersCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const insets = useSafeAreaInsets();
  const fadeAnim = useState(new Animated.Value(0))[0];

  useEffect(() => {
    loadBuyers();

    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, []);

  const loadBuyers = async () => {
    if (!evento || !evento.ticketHistory) {
      return;
    }

    const ticketHistory = Object.values(evento.ticketHistory);
    const buyersData = await getBuyersData(ticketHistory);

    // Separar compradores públicos e privados
    const publicBuyersArray = [];
    let privateBuyersCounter = 0;

    buyersData.forEach((buyer) => {
      const isPublicOrFollowing =
        buyer.uid === uid || buyer.isPublic || buyer.followers.includes(uid);
      const isEventHidden = buyer.hiddenEvents.includes(evento.username);
      const isCurrentUser = buyer.uid === uid;

      if ((isPublicOrFollowing && !isEventHidden) || isCurrentUser) {
        publicBuyersArray.push(buyer);
      } else {
        privateBuyersCounter++;
      }
    });

    setPublicBuyers(publicBuyersArray);
    setPrivateBuyersCount(privateBuyersCounter);
  };

  const renderAvatars = () => {
    const avatars = [];
    for (let i = 0; i < 5; i++) {
      const buyer = publicBuyers[i];
      if (buyer) {
        avatars.push(
          <Image
            key={i}
            source={
              buyer.avatarUrl ? { uri: buyer.avatarUrl } : defaultAvatarImage
            }
            style={[styles.avatar, { zIndex: 5 - i }]}
          />
        );
      } else {
        // Sem comprador nesse índice, usar emptyAvatarImage
        avatars.push(
          <Image
            key={i}
            source={emptyAvatarImage}
            style={[styles.avatar, { zIndex: 5 - i }]}
          />
        );
      }
    }
    return avatars;
  };

  const renderRemainingBuyersCount = () => {
    return (
      <Text style={styles.remainingCount}>e mais algumas pessoas</Text>
    );
  };

  const openBuyersModal = () => {
    setModalVisible(true);
  };

  const handleProfileNavigation = async (buyer) => {
    try {
      setModalVisible(false); // Fechar o modal ao clicar em um perfil

      if (buyer.uid == uid) {
        navigation.navigate("Profile");
      } else {
        const completeBuyerData = await getUserProfile(buyer.uid);
        const isFollowing = await checkIfFollows(uid, buyer.uid);
        const hiddenEvents = await checkHiddenEvents(
          completeBuyerData,
          evento.username
        );
        if (
          completeBuyerData &&
          (completeBuyerData.isPublic == true || isFollowing == true) &&
          hiddenEvents == false
        ) {
          navigation.navigate("VisitProfile", {
            usuario: completeBuyerData,
            currentUser: uid,
          });
        } else {
          console.error(
            "Dados completos do usuário não encontrados ou o perfil é privado"
          );
        }
      }
    } catch (error) {
      console.error("Erro ao obter dados completos do usuário:", error);
    }
  };

  if (publicBuyers.length === 0 && privateBuyersCount === 0) {
    return null;
  }

  return (
    <SafeAreaView style={styles.container}>
      <Animated.View style={{ opacity: fadeAnim }}>
        <TouchableOpacity
          style={styles.avatarsContainer}
          onPress={openBuyersModal}
        >
          {renderAvatars()}
          {renderRemainingBuyersCount()}
        </TouchableOpacity>
      </Animated.View>
      <Modal
        visible={modalVisible}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)}
        transparent={true}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View
                style={[
                  styles.modalContainer,
                  Platform.OS === "ios" && {
                    paddingTop: insets.top,
                  },
                ]}
              >
                <FlatList
                  data={publicBuyers}
                  renderItem={({ item }) => (
                    <TouchableOpacity
                      style={styles.buyerItem}
                      onPress={() => handleProfileNavigation(item)}
                    >
                      <Image
                        source={
                          item.avatarUrl
                            ? { uri: item.avatarUrl }
                            : defaultAvatarImage
                        }
                        style={styles.buyerAvatar}
                      />
                      <View style={styles.buyerInfo}>
                        <Text style={styles.buyerName}>
                          {`${item.fullName}${
                            item.uid === uid ? " (eu)" : ""
                          }`}
                        </Text>
                        <Text style={styles.buyerUsername}>
                          @{item.username}
                        </Text>
                        {item.tickets > 1 && (
                          <Text style={styles.ticketCount}>
                            Mais de um ingresso
                          </Text>
                        )}
                      </View>
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item) => item.uid}
                />
                {privateBuyersCount > 0 && (
                  <View style={styles.privateProfilesMessage}>
                    <Text style={styles.privateProfilesText}>
                      e mais alguns perfis privados
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  style={styles.closeModalButton}
                  onPress={() => setModalVisible(false)}
                >
                  <Text style={styles.closeModalText}>Fechar</Text>
                </TouchableOpacity>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#000",
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  avatarsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "#000",
    borderRadius: 5,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: -10,
    borderWidth: 1,
    borderColor: "#000",
  },
  remainingCount: {
    color: "#fff",
    marginLeft: 15,
    fontSize: 14,
  },
  buyerItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#333",
  },
  buyerAvatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 12,
  },
  buyerInfo: {
    justifyContent: "center",
  },
  buyerName: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  buyerUsername: {
    color: "#ccc",
    fontSize: 14,
  },
  ticketCount: {
    color: "#999",
    fontSize: 12,
  },
  closeModalButton: {
    padding: 15,
    backgroundColor: "#111",
    alignItems: "center",
  },
  closeModalText: {
    color: "#fff",
    fontSize: 16,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "#00000099",
    justifyContent: "flex-end",
  },
  modalContainer: {
    maxHeight: "70%",
    backgroundColor: "#000",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: "hidden",
  },
  privateProfilesMessage: {
    padding: 12,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: "#333",
  },
  privateProfilesText: {
    color: "#ccc",
    fontSize: 14,
    textAlign: "center",
  },
});

export default BuyersList;
