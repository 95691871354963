import React, { useState, useEffect } from "react";
import {
  View,
  Modal,
  StyleSheet,
  FlatList,
  TextInput,
  TouchableOpacity,
  Text,
  SafeAreaView,
  ActivityIndicator,
  Platform,
  TouchableWithoutFeedback,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[-/\\^$*+?.()|[\]{}]/g, "");
};

const CitySelector = ({
  onSelectCity,
  visible,
  onRequestClose,
  selectedCity,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [cities, setCities] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(visible);
  const [loading, setLoading] = useState(false);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  useEffect(() => {
    const fetchCities = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://servicodados.ibge.gov.br/api/v1/localidades/distritos?orderBy=nome"
        );
        const data = await response.json();
        const cityNames = data.map((city) => city.nome);
        setCities(cityNames);
      } catch (error) {
        console.error("Erro ao buscar cidades:", error);
      }
      setLoading(false);
    };

    fetchCities();
  }, []);

  const filteredCities = cities.filter((city) =>
    removeAccents(city)
      .toLowerCase()
      .includes(removeAccents(searchQuery).toLowerCase())
  );

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  return (
    <SafeAreaView style={styles.container}>
      <TouchableOpacity
        style={styles.selector}
        onPress={toggleModal}
        activeOpacity={0.7}
      >
        <Ionicons name="location" size={24} color="white" />
        <Text style={styles.selectedCity}>{selectedCity}</Text>
        <Ionicons name="chevron-down" size={24} color="white" />
      </TouchableOpacity>

      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={toggleModal}
      >
        <TouchableWithoutFeedback onPress={toggleModal}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View
                style={[
                  styles.modalContainer,
                  Platform.OS === "ios" && {
                    paddingTop: insets.top,
                  },
                ]}
              >
                <View style={styles.modalHeader}>
                  <Text style={styles.modalTitle}>Selecione sua cidade</Text>
                  <TouchableOpacity onPress={toggleModal}>
                    <Ionicons name="close" size={24} color="#FFF" />
                  </TouchableOpacity>
                </View>
                <View style={styles.searchSection}>
                  <TextInput
                    style={styles.input}
                    onChangeText={setSearchQuery}
                    value={searchQuery}
                    placeholder="Buscar cidade"
                    placeholderTextColor="#CCC"
                    autoCapitalize="none"
                  />
                  {searchQuery.length > 0 && (
                    <TouchableOpacity
                      onPress={clearSearch}
                      style={styles.clearButton}
                    >
                      <Ionicons name="close-circle" size={20} color="#CCC" />
                    </TouchableOpacity>
                  )}
                </View>
                {loading ? (
                  <ActivityIndicator size="large" color="#FFF" />
                ) : (
                  <>
                    <TouchableOpacity
                      style={styles.allCitiesButton}
                      onPress={() => {
                        onSelectCity("Todas as Cidades");
                        toggleModal();
                      }}
                    >
                      <Text style={styles.allCitiesText}>Todas as Cidades</Text>
                    </TouchableOpacity>
                    <FlatList
                      data={filteredCities}
                      renderItem={({ item }) => (
                        <TouchableOpacity
                          style={styles.item}
                          onPress={() => {
                            onSelectCity(item);
                            toggleModal();
                          }}
                        >
                          <Text style={styles.itemText}>{item}</Text>
                        </TouchableOpacity>
                      )}
                      keyExtractor={(item, index) => index.toString()}
                      style={styles.list}
                    />
                  </>
                )}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {},
  selector: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: 5,
    paddingHorizontal: 45,
    borderRadius: 8,
    backgroundColor: "#000",
  },
  selectedCity: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 14,
    marginHorizontal: 5,
  },
  allCitiesButton: {
    padding: 5,
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "#141414",
    borderRadius: 10,
    paddingHorizontal: 25,
    marginBottom: 10,
  },
  allCitiesText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "#00000099",
    justifyContent: "flex-end",
  },
  modalContainer: {
    maxHeight: "70%",
    backgroundColor: "#000",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: 16,
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFF",
  },
  input: {
    flex: 1,
    backgroundColor: "#222",
    color: "#FFF",
  },
  list: {
    marginTop: 10,
  },
  item: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#444",
  },
  itemText: {
    color: "#FFF",
    fontSize: 16,
  },
  searchSection: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#222",
    borderRadius: 15,
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontSize: 16,
    marginVertical: 10,
  },
  clearButton: {
    marginLeft: "auto",
    marginRight: 10,
  },
});

export default CitySelector;
